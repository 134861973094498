/*
 * 업무구분: 고객
 * 화 면 명: MSPCM252D
 * 화면설명: 대출가망고객 - 보험계약대출(디비전)
 * 작 성 일: 2023.03.29
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="" :show-title="false" :topButton="false">
    <ur-box-container v-if="cMLnPsbCustSVO.length > 0" alignV="start" componentid="" direction="column" class="ns-check-list">  
      <mo-list :list-data="cMLnPsbCustSVO">
        <template #list-item="{index, item}">
          <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
            <mo-checkbox v-model="item.checked" :disabled="checkDisabled" :ref="`chk${index}`"/>
            <div class="list-item__contents">
              <!-- 이름 -->
              <div class="list-item__contents__title">
                <!-- 고객카드 없음 240129 추가 -->
                <span class="name" style="display: flex; padding-right: 18px;">
                  <span class="txtSkip" @click.stop="fn_OpenCustInfoBs(item)">
                    {{ item.custNm }}
                  </span>
                  <span :class="!item.chnlCustId.trim() ? 'need-cs-card' : null" />
                </span>
                <!-- 고객카드 없음 240129 추가 -->
              </div>
              <!-- 생년 / 성별 / 휴대폰번호 -->
              <div class="list-item__contents__info">
                <span>{{$commonUtil.dateDashFormat($bizUtil.getDotDateToFullDate(item.rrnFrnoNo))}}</span>
                <em>|</em><span>{{item.sxdsCdNm === '남자'? '남': '여'}}</span><em>|</em><span>{{item.telno}}</span>
              </div>
              <div class="list-item__contents__info">
                <span>마케팅동의</span><b class="ml10 crTy-blue2" v-if="item.mktPrcusCnsntYn === 'Y'">{{item.mktPrcusCnsntYn}}</b><b class="ml10 crTy-orange2" v-else>N</b>
              </div>
              <div class="list-item__contents__info fex-rw" v-if="!$bizUtil.isEmpty(item.pllnOccuNote)">
                <span class="crTy-bk7 fs14rem mr10 min50">비고</span><span class="txtSkip2">{{item.pllnOccuNote}}</span>
              </div>
              <div class="list-item__contents__info">
                <span class="crTy-bk7 fs14rem mr10">실적기준</span><span>{{item.pllnOccuYmd}}</span>
              </div>
            </div>
          </mo-list-item>
        </template>
      </mo-list>  
    </ur-box-container>

    <!-- 고객카드 미등록고객안내 bottomSheet -->
    <mo-bottom-sheet ref="bottomSheetCust" class="ns-bottom-sheet ns-style3">
      <div class="customer-info-txt txt-center">
        <p class="ment">고객카드 미등록 고객이므로 진행이 불가합니다.</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM252D",
  screenId: "MSPCM252D",
  props: ['cMLnPsbCustSVO', 'pOrgData250M', 'userInfo'],
  components: {
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},

  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  computed: {
    selectItems() {
      return this.cMLnPsbCustSVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cMLnPsbCustSVO.filter(item => item.checked).length
    },
    checkDisabled () {
      if(this.pOrgData250M.cnslt === undefined || this.pOrgData250M.cnslt === null || this.pOrgData250M.cnslt === ''){
        return false
      }else{
        if (this.userInfo.userId === this.pOrgData250M.cnslt.key) return false
        else return true
      }
    }
  },
  
  watch: {
    checkCount() {
      // 체크 시 BottomSheet 컨트롤
      // 고객툴바 호출
      this.$cmUtil.setSelectedCustList(this, this.selectItems, 'MSPCM250M')

      // 선택된 항목 길이 전달
      this.$emit('checkCount', {checkCount: this.checkCount})
    },
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      // 대출가망고객 태블릿과 안내문구 통일
      if (this.$bizUtil.isEmpty(item.chnlCustId)) {
        this.$refs.bottomSheetCust.open()
        return
      }

      if(this.pOrgData250M.cnslt === undefined || this.pOrgData250M.cnslt === null || this.pOrgData250M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData250M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : this.$options.screenId, //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetCust !== undefined) {
          lv_Vm.$refs.bottomSheetCust.close()
        }
      }
    },
  }
};
</script>
<style scoped>
</style>